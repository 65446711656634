import React, { useState, useEffect } from 'react';
import image1 from '../assets/images/dal.jpg';
import image2 from '../assets/images/bramsar.jpg';
import image3 from '../assets/images/durinar.jpg';
import image4 from '../assets/images/Masbal.jpg';
import image5 from '../assets/images/houseboat.jpg';

const images = [
  { src: image5, caption: "Luxurious Houseboats" },
  { src: image1, caption: "Amazing Dal Lake" },
  { src: image3, caption: "Great Experience" },
  { src: image4, caption: "Stunning Mansbal Lake" },
  { src: image2, caption: "Hidden Gems" },
];

const Welcome = () => {
  const [currentImage, setCurrentImage] = useState(0);
  
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage + 1) % images.length);
    }, 3000);

    return () => clearInterval(intervalId);
  }, [currentImage]);

  return (
    <div className="relative w-full overflow-hidden h-screen">
      {images.map((image, index) => (
        <div 
          key={image.caption} 
          className={`absolute inset-0 w-full h-full transition-opacity duration-1000 ease-in-out ${currentImage === index ? 'opacity-100' : 'opacity-0'}`}
        >
          <img 
            src={image.src} 
            alt={image.caption} 
            loading='lazy'
            className="w-full h-full object-cover "
          />
        </div>
      ))}
      <div className="absolute inset-0 flex items-center justify-center">
        <div className="text-center px-4">
          <h2 className="text-white text-2xl font-serif italic subpixel-antialiased ... sm:text-3xl md:text-4xl font-bold break-words">{images[currentImage].caption}</h2>
        </div>
      </div>
      <div className="absolute bottom-0 w-full flex justify-center p-4">
        {images.map((_, index) => (
          <button 
            key={index} 
            className={`h-3 w-3 mx-1 rounded-full ${currentImage === index ? 'bg-yellow-500' : 'bg-white'}`}
            onClick={() => setCurrentImage(index)}
            aria-label={`Go to slide ${index + 1}`}
          />
        ))}
      </div>
    </div>
  );
};


export default Welcome;