import React from 'react';
import { FaFacebookF, FaInstagram, FaWhatsapp } from 'react-icons/fa'; // You may need to install react-icons if you haven't already

const Footer = () => {
    return (
        <footer className="bg-gray-800 text-white pt-10 pb-5">
            <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 text-center md:text-left">
                <div>
                    <h5 className="text-xl font-bold font-serif mb-2">Dial Kashmir Travels</h5>
                    <p className="mb-5 font-serif">See the best of Kashmir with us! We provide complete travel solutions. We do services on Flights, Hotels, Holidays, MICE, Adventures and other facilities.</p>
                    <div className="flex justify-center md:justify-start space-x-4">
                        <a href="https://www.facebook.com/profile.php?id=61554746730530&mibextid=ZbWKwL" className="text-gray-400 hover:text-gray-100" target="_blank" rel="noopener noreferrer"><FaFacebookF /></a>
                        <a href="https://www.instagram.com/dailkashmirtravels?igshid=MTNiYzNiMzkwZA==" className="text-gray-400 hover:text-gray-100" target="_blank" rel="noopener noreferrer"><FaInstagram /></a>
                        <a href="https://wa.me/message/2SJ5OJW2GAX2E1" className="text-gray-400 hover:text-gray-100" target="_blank" rel="noopener noreferrer"><FaWhatsapp /></a>
                    </div>
                </div>
                <div>
                    <h5 className="text-xl font-bold font-serif mb-2">Book Now</h5>
                    <p className='font-serif'>Flight</p>
                    <p className='font-serif'>Hotels</p>
                    <p className='font-serif'>Tour</p>
                    <p className='font-serif'>Car Rent</p>
                </div>
                <div>
                    <h5 className="text-xl font-bold font-serif mb-2">Contact Information</h5>
                    <a href="https://www.google.com/maps/place/34%C2%B006'19.0%22N+74%C2%B053'20.8%22E/@34.1052731,74.8884683,19z/data=!3m1!4b1!4m4!3m3!8m2!3d34.105272!4d74.889112?entry=ttu" target="_blank" rel="noopener noreferrer">
                        <p className='font-serif'>Pahloo Brein Nishat, Srinagar Kashmir, 191121</p>
                    </a>
                    <a href="tel:+917006444503">
                        <p className='font-serif'>+91 7006444503</p>
                    </a>
                    <a href="tel:+919906774568">
                        <p className='font-serif'>+91 9906774568</p>
                    </a>
                    <a href={`mailto:{info@dailkashmir.in}`} className='font-serif'>info@dailkashmir.in</a><br />
                    <a href={`mailto:{dialkashmirtourandtravels1@gmail.com }`} className='font-serif'>dialkashmirtourandtravels1@gmail.com </a>
                </div>

            </div>
            <div className="text-center font-serif border-t border-gray-700 mt-4 pt-4">
                <p>Copyright ©2023 All rights reserved | DK Travels</p>
            </div>
        </footer>
    );
};

export default Footer;