import React from 'react';

const About = () => {
  return (
    <div className="container mx-auto px-4 py-12 bg-white shadow rounded-lg">
      <div className="text-center max-w-2xl mx-auto">
        <h1 className="text-3xl font-bold font-serif text-gray-800 mb-6">Welcome to Dial Kashmir Tour and Travels</h1>
        <h2 className="text-2xl font-serif text-gray-600 mb-6">A tradition of excellence</h2>
        <p className="text-gray-600 text-lg font-serif leading-relaxed mb-6">
          Dial Kashmir Tour and Travels gives you experiences which one have never ever gone through and will never ever go through in your life. You are like family to us and we will treat you like the one. Your satisfaction is our first priority. We are truly be grateful to you for choosing us as your service provider and giving us the opportunity to grow. None of our achievements would have been possible without you and your unwavering support. We know what you want from us and how to make this experience the best ever for you. Our team management would like to thank you for giving us an opportunity to serve you. It is our desire to keep you satisfied with our services and products. You are our inspiration in doing our best. Thank you and remember, we value you!
        </p>
      </div>
    </div>
  );
};

export default About;
