import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';
import emailjs from 'emailjs-com';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import pkg1 from '../assets/images/pkg1.jpg';
import pkg2 from '../assets/images/pkg2.jpg'; 
import pkg3 from '../assets/images/pkg3.jpg';
import pkg4 from '../assets/images/pkg4.jpg';

const commonDescription = [
  'Pick-Up And Drop Service.',
  'Nights Stay At Hotel.',
  'Breakfast And Dinner In Hotel.',
  'Sightseeing By Individual Car.',
  'Toll Tax, Parking, Drive Etc. All Include.'
];

const packages = [
  { id:1, name: 'Kashmir Honeymoon Package', description: commonDescription, image: pkg1, tag: '5 Nights / 6 Days' },
  { id:2, name: 'Kashmir Group Tour Package', description: commonDescription, image: pkg2 , tag: '7 Nights / 8 Days'},
  { id:3, name: 'Kashmir Family Tour Package', description: commonDescription, image: pkg3, tag: '3 Nights / 4 Days'},
  { id: 4, name: 'Kashmir Valley Package', description: commonDescription, image: pkg4, tag: '4 Nights / 5 Days' },
];

Modal.setAppElement('#root');

const Packages = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState(null);

  const openModal = (pkg) => {
    setSelectedPackage(pkg);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedPackage(null);
    setIsModalOpen(false);
  };

  const submitEnquiry = (e) => {
    e.preventDefault();
    e.preventDefault();
    const serviceId = "service_piqdkyl";
    const templateId = "template_375sp0m";
    const userId = "eQeFmAVBASMRQBdyl";

    emailjs
      .sendForm(serviceId, templateId, e.target, userId)
      .then(
        (result) => {
          toast.success('Email sent successfully!', {
            position: 'bottom-right',
            autoClose: 3000,
            onClose: () => setIsModalOpen(false),
          });;
        },
        (error) => {
          toast.error('Email sending error: ' + error.text, {
            position: 'bottom-right',
          });
        }
      );
  };

  return (
    <div className="container mx-auto px-6 py-8">
      <h2 className="text-3xl font-bold font-serif text-center text-gray-800 mb-8">Packages</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {packages.map((pkg, index) => (
          <div key={index} className="group overflow-hidden bg-white shadow-lg rounded-lg cursor-pointer transition-all duration-300 ease-in-out relative">
            <div className="relative">
              <img src={pkg.image} loading='lazy' alt={pkg.name} className="w-full h-44 object-cover" />
              <div className="absolute top-1 right-1 bg-green-500 rounded-md text-white p-2">{pkg.tag}</div>
              <div className="p-2">
              <h3 className="text-sm font-bold">{pkg.name}</h3>
              <ul className='ml-3 list-disc text-xs'>
                {pkg.description.map((item, i) => (
                  <li key={i}>{item}</li>
                ))}
              </ul>
            </div>
              <div className="flex justify-between mt-4">
                <button
                  onClick={() => openModal(pkg)}
                  className="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-white focus:outline-none bg-green-500 rounded-full border border-gray-200 hover:bg-gray-800"
                >
                  Enquiry
                </button>
                <Link
                  to={`/package/${pkg.id}`}
                  className="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-white focus:outline-none bg-blue-800 rounded-full border border-gray-200 hover:bg-gray-800"
                >
                  Details
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
      <Modal 
        isOpen={isModalOpen} 
        onRequestClose={closeModal}
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: '50',
          },
          content: {
            position: 'relative',
            inset: 'auto',
            margin: 'auto',
            background: '#fff',
            overflow: 'auto',
            borderRadius: '2rem',
            outline: 'none',
            padding: 'auto',
            width: '90%',
            maxWidth: '35rem',
            maxHeight: '90vh',
            zIndex: '100',
          }
        }}>
        <div className="relative p-4 bg-white mx-auto my-auto rounded-md shadow-lg w-full max-w-md">
          <div className="flex justify-between items-start p-4 rounded-t border-b">
            <h2 className="text-xl md:text-2xl font-bold text-gray-900">YES I WANT DETAILS</h2>
            <button 
              onClick={closeModal} 
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
            >
              X
            </button>
          </div>
          <form onSubmit={submitEnquiry} className="px-6 pb-4 space-y-6 lg:px-8 sm:pb-6 xl:pb-8">
            <input 
              type="hidden" 
              name="packageName" 
              value={selectedPackage ? selectedPackage.name : ''} 
            />
            <input 
              type="text" 
              name='clientName'
              placeholder="Enter Name" 
              required 
              className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
            />
            <input 
              type="tel" 
              name='clientPhone'
              placeholder="Enter Phone" 
              required 
              className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
            />
            <input 
              type="email" 
              name='clientEmail'
              placeholder="Enter email" 
              required 
              className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
            />
            <input 
              type="number" 
              name='noOfPerson'
              placeholder="No. of Person" 
              min={1}
              required 
              className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
            />
            <button 
              type="submit" 
              className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-green-500 rounded-md hover:bg-green-600 focus:outline-none focus:bg-green-600"
            >
              Send Enquiry
            </button>
          </form>
        </div>
      </Modal>

      </div>
  );
};

export default Packages;
