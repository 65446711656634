import React from 'react';
import DestinationImage1 from '../assets/images/gulmarg.jpg';
import DestinationImage2 from '../assets/images/pahalgam.jpg'; 
import DestinationImage3 from '../assets/images/Doodhpatri.jpg';
import DestinationImage4 from '../assets/images/Aharbal.jpg';
import DestinationImage5 from '../assets/images/sonamarg.jpg';
import DestinationImage6 from '../assets/images/KGL.jpg';
import DestinationImage7 from '../assets/images/greater_lakes.jpg';
import DestinationImage8 from '../assets/images/sheehnaag.jpg';
import DestinationImage9 from '../assets/images/verinag.jpg';

const destinations = [
  { name: 'Gulmarg', description: 'Sunny side of life.', image: DestinationImage1 },
  { name: 'Pahalgam', description: 'The land of beauty.', image: DestinationImage2 },
  { name: 'Doodhpathri', description: 'The heartwarming meadows.', image: DestinationImage3 },
  { name: 'Aharbal', description: 'The majestic waterfall.', image: DestinationImage4 },
  { name: 'Sonamarg', description: 'The meadow of gold.', image: DestinationImage5 },
  { name: 'Kashmir Great Lakes', description: 'The jewels in the mountains.', image: DestinationImage6},
  { name: 'Greater Lakes', description: 'The alpine wonders..', image: DestinationImage7},
  { name: 'Sheeshnag', description: 'The fluorescent crystal.', image: DestinationImage8},
  { name: 'Verinag', description: 'The spring of bliss.', image: DestinationImage9}
];

const Destinations = () => {
  return (
    <div className="container mx-auto px-6 py-8">
      <h2 className="text-3xl font-bold text-center text-gray-800 mb-8 font-serif">Destinations</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
        {destinations.map((destination, index) => (
          <div key={index} className="group overflow-hidden bg-white shadow-lg rounded-lg cursor-pointer">
            <div className="relative">
              <img
                src={destination.image}
                loading="lazy"
                alt={destination.name}
                className="w-full h-64 object-cover transition-transform hover:-translate-y-1 hover:scale-105 duration-300 ease-in-out"
              />
              <div className="absolute bottom-0 w-full h-1/3 bg-opacity-50 text-white p-4 flex flex-col justify-center md:bg-transparent md:text-transparent group-hover:text-white group-hover:bg-opacity-50 transition-all duration-300 ease-in-out">
                <h3 className="text-xl font-bold font-sans hover:text-indigo-300 hover:font-serif transition-colors duration-300">
                  {destination.name}
                </h3>
                <p className="text-sm font-serif">{destination.description}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Destinations;
