import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import LogoSVG from '../../assets/logo.png';

const NavBar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleNavigation = (href, isRoute) => {
    if (isRoute) {
      window.location.href = href;
    } else {
      if (window.location.pathname !== '/') {
        window.location.href = `/${href}`;
      } else {
        window.location.hash = href;
      }
      closeMenu(); // Close the menu after navigation
    }
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const navigationLinks = [
    { name: 'Home', href: '#home-section', isRoute: false },
    { name: 'About', href: '#about-section', isRoute: false },
    { name: 'Packages', href: '#packages-section', isRoute: false},
    { name: 'Destinations', href: '#destinations-section', isRoute: false },
    { name: 'Contact Us', href: '/book', isRoute: true },
  ];

  const contactDetails = {
    phoneNumbers: ['+91 7006 444 503', '+91 9906 774 568'],
    email: 'info@dialkashmir.in',
  };

  return (
    <div className="relative">
      <nav className="bg-gray-800 text-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-16">
            {/* Logo */}
            <div className="flex-shrink-0">
              <Link to="/" className="block">
                <div className="w-12 h-12 rounded-full overflow-hidden bg-white">
                  <img src={LogoSVG} alt="Pinnacle Travels Logo" className="h-full w-full object-contain" />
                </div>
              </Link>
            </div>
            {/* Desktop Menu */}
            <div className="hidden md:block">
              <div className="ml-10 flex items-baseline space-x-4">
                {navigationLinks.map((link) => (
                  <a key={link.name} href={link.href} onClick={(e) => { e.preventDefault(); handleNavigation(link.href, link.isRoute); }} className="text-gray-300 font-serif hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">
                    {link.name}
                  </a>
                ))}
                {/* Contact Details as Links */}
                <div className="flex items-center space-x-4 text-sm">
                  <a href={`tel:${contactDetails.phoneNumbers[0]}`} className="text-gray-300 font-serif text-xs">{contactDetails.phoneNumbers[0]}</a>
                  <a href={`tel:${contactDetails.phoneNumbers[1]}`} className="text-gray-300 font-serif text-xs">{contactDetails.phoneNumbers[1]}</a>
                  <a href={`mailto:${contactDetails.email}`} className="text-gray-300 font-serif text-xs">{contactDetails.email}</a>
                </div>
              </div>
            </div>
            {/* Mobile Menu Button */}
            <div className="md:hidden">
              <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 font-serif hover:text-white hover:bg-gray-700 focus:outline-none">
                <span className="sr-only">Open main menu</span>
                {isMenuOpen ? <XIcon className="block h-6 w-6" /> : <MenuIcon className="block h-6 w-6" />}
              </button>
            </div>
          </div>
        </div>
        {/* Mobile Menu */}
        <div className={`${isMenuOpen ? 'block' : 'hidden'} md:hidden`}>
          {navigationLinks.map((link) => (
            <a key={link.name} href={link.href} onClick={(e) => { e.preventDefault(); handleNavigation(link.href, link.isRoute); closeMenu(); }} className="block px-3 py-2 rounded-md text-base font-medium font-serif text-white bg-gray-900">
              {link.name}
            </a>
          ))}
          {/* Contact Details in Mobile Menu as Links */}
          <div className="flex flex-col items-center space-y-2 mt-4 text-sm">
            <a href={`tel:${contactDetails.phoneNumbers[0]}`} className="text-gray-300 font-serif text-xs">{contactDetails.phoneNumbers[0]}</a>
            <a href={`tel:${contactDetails.phoneNumbers[1]}`} className="text-gray-300 font-serif text-xs">{contactDetails.phoneNumbers[1]}</a>
            <a href={`mailto:${contactDetails.email}`} className="text-gray-300 font-serif text-xs">{contactDetails.email}</a>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default NavBar;