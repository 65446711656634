import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import NavBar from './components/layout/NavBar';
import Destinations from './components/Destinations';
import About from './components/About';
import Welcome from './components/Welcome';
import Footer from './components/layout/Footer';
import BookingForm from './components/BookingForm';
import PackageDetails from './components/Details/PackageDetails';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import Packages from './components/Packages';
import WhatsAppButton from './components/common/WhatsAppButton';

function App() {
  return (
    <Router>
      <NavBar />
      <WhatsAppButton />
      <ToastContainer position="bottom-right" />
      <ToastContainer position="bottom-right" />
      <TransitionGroup>
        <CSSTransition classNames="fade" timeout={300}>
          <Routes>
            <Route path="/" element={
              <div>
                <div id="home-section"> <Welcome/> </div>
                <div className="common-container">
                <div id="about-section"><About /></div>
                <div id="packages-section"><Packages /></div>
                </div>
                <div id="destinations-section"> <Destinations/> </div>
              </div>
            } />
            <Route path="/book" element={<BookingForm />} />
            <Route path="/package/:packageId" element={<PackageDetails />} />
          </Routes>
        </CSSTransition>
      </TransitionGroup>
      <Footer />
    </Router>
  );
}

export default App;
