import React from 'react';
import { FaWhatsapp } from 'react-icons/fa';

const WhatsAppButton = () => {
    const openWhatsApp = () => {
        window.open(`https://wa.me/message/2SJ5OJW2GAX2E1`, '_blank');
    };    
    return (
        <div className="fixed right-9 bottom-9 z-10">
            <button onClick={openWhatsApp} className="inline-flex item-center justify-center w-16 h-16 mr-2 rounded-full focus:shadow-outline bg-green-600 text-white transition ease-in-out delay-50 hover:-translate-y-1 hover:scale-110 duration-300 ... hover:text-blue-500">
                <FaWhatsapp  className='w-10 h-10 mt-3'/>
            </button>
        </div>
    );
};

export default WhatsAppButton;
