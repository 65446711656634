import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';

const BookingForm = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const serviceId = "service_piqdkyl";
    const templateId = "template_6eui99b";
    const userId = "eQeFmAVBASMRQBdyl";

    emailjs
      .sendForm(serviceId, templateId, e.target, userId)
      .then(
        (result) => {
          toast.success('Email sent successfully!', {
            position: 'bottom-right',
            autoClose: 3000,
            onClose: () => navigate('/'),
          });
          setFormData({ name: '', email: '', subject: '', message: '' });
        },
        (error) => {
          toast.error('Email sending error: ' + error.text, {
            position: 'bottom-right',
          });
        }
      );
  };

  return (
    <section className="bg-white flex flex-col md:flex-row">
      <div className="py-8 lg:py-16 px-4 mx-auto max-w-screen-md w-full md:w-1/2 lg:w-90">
        <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-center text-gray-900 dark:text-grey-600">Contact Us</h2>
        <form onSubmit={handleSubmit} className="space-y-2">
          <div>
            <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800">Your name</label>
            <input type="name" name="name" value={formData.name} onChange={handleChange} required
              className="shadow-sm bg-gray-50 border border-gray-300 text-gray-300 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-100 dark:border-gray-100 dark:placeholder-gray-700 dark:text-black dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"/>
          </div>
          <div>
            <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-600">Your email</label>
            <input type="email" name="email" value={formData.email} onChange={handleChange} required
              className="shadow-sm bg-gray-50 border border-gray-300 text-gray-300 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray100 dark:border-gray-100 dark:placeholder-gray-700 dark:text-black dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light" />
          </div>
          <div>
            <label htmlFor="subject" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-700">Subject</label>
            <input type="text" name="subject" value={formData.subject} onChange={handleChange} required
              className="block p-3 w-full text-sm text-gray-300 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-100 dark:border-gray-100 dark:placeholder-gray-700 dark:text-black dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"/>
          </div>
          <div className="sm:col-span-2">
            <label htmlFor="message" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-700">Your message</label>
            <textarea name="message" rows="6" value={formData.message} onChange={handleChange} required
              className="block p-2.5 w-full text-sm text-gray-300 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-100 dark:border-gray-100 dark:placeholder-gray-700 dark:text-black dark:focus:ring-primary-500 dark:focus:border-primary-500"></textarea>
          </div>
          <button
            type="submit"
            className="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-full border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 ..."
          >
            Send message
          </button>
        </form>
      </div>

      <div className="py-8 lg:py-16 px-4 mx-auto max-w-screen-md w-full md:w-1/2 lg:w-90">
        <iframe
          width="90%"
          height="500"
          allowFullScreen=""
          loading="lazy"
          src="https://maps.google.com/maps?q=34°06'19.0 N 74°53'20.8 E&t=&z=10&ie=UTF8&iwloc=&output=embed"
          title="GoogleMapAddress"
        ></iframe>
      </div>
    </section>
  );
};

export default BookingForm;
