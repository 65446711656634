import React , { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import packageData from './Utils/PackagesDetailsConstant.json';

const PackageDetails = () => {
  const { packageId } = useParams();
  const pkg = packageData.find(p => p.id.toString() === packageId);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!pkg) {
    return (
      <div className="flex justify-center items-center h-screen">
        <h1 className="text-3xl font-semibold text-gray-800">Package not found</h1>
      </div>
    );
  }

  return (
    <div className="bg-gray-100 py-8 px-4 md:px-10">
      <div className="max-w-3xl mx-auto bg-white rounded-lg overflow-hidden shadow-lg">
        <div className="text-center p-8">
          <h1 className="text-4xl font-bold text-indigo-600">{pkg.name}</h1>
          <div className="mt-4">
            <p className="font-semibold text-xl">Duration: {pkg.NoOfNights}</p>
            <p className="font-semibold text-xl">Meal Plan: {pkg.MealPlan}</p>
            <p className="font-semibold text-xl">Transportation: {pkg.Transportation}</p>
          </div>
        </div>
        <div className="border-t border-gray-200">
          {pkg.Itinerary.map((day, index) => (
            <div key={index} className="px-8 py-4 border-b border-gray-200">
              <h2 className="text-2xl font-semibold text-gray-800">{day.Day}</h2>
              <h3 className="text-lg text-gray-600">{day.Location}</h3>
              <p className="text-gray-500">{day.Description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PackageDetails;
